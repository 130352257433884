import React, { useState } from "react";
import OneSignal from "react-onesignal";

export default function NotificationBtn(props) {
  const [status, setStatus] = useState("default");

  const platform = window.navigator.platform;
  const iosPlatforms = ["iPhone", "iPad", "iPod"];

  let isItIphone;

  if (iosPlatforms.indexOf(platform) !== -1) {
    isItIphone = true;
  } else {
    isItIphone = false;
  }

  async function getStaus() {
    const newstatus = await OneSignal.getNotificationPermission();
    setStatus(newstatus);
  }
  getStaus();

  OneSignal.on("subscriptionChange", function(status) {
    if (status) setStatus("granted");
    else if (!status) setStatus("denid");
  });

  function DefaultBtn() {
    return (
      <button
        style={props.style}
        className="btn btn-light btn-glow btn-style"
        onClick={() => {
          OneSignal.showNativePrompt();
        }}>
        Enable Notifications
        <i className=" ms-2 bi bi-bell-fill"></i>
      </button>
    );
  }

  function GrantedBtn() {
    return (
      <button className="btn btn-success " style={{ fontWeight: "500" }}>
        Notifications are Enabled
        <i className="ms-2 bi bi-check-circle-fill"></i>
      </button>
    );
  }

  function DeniedBtn() {
    return (
      <button className="btn btn-danger" style={{ fontWeight: "500" }}>
        Notifications are Blocked
        <i className="ms-2 bi bi-slash-circle"></i>
      </button>
    );
  }

  function getButton() {
    if (status === "default") return <DefaultBtn />;
    else if (status === "granted") return <GrantedBtn />;
    else if (status === "denied") return <DeniedBtn />;
    else return <DefaultBtn />;
  }

  return <>{isItIphone ? null : getButton()}</>;
}
