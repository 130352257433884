import NotificationBtn from "./NotificationBtn";

function Navbar() {
  return (
    <nav className="text-center d-flex gap-3 align-items-center justify-content-center flex-wrap justify-content-md-between p-3">
      <a className="logo   " href="/">
        <img alt="logo me-1" src="logo.png" className="logo" />
      </a>
      <NotificationBtn />
    </nav>
  );
}

export default Navbar;
