import React, { lazy, Suspense } from "react";
import Navbar from "./Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//import Home from "./pages/Home";
//import Admin from "./pages/Admin";

const LazyHome = lazy(() => import("./pages/Home"));
const LazyAdmin = lazy(() => import("./pages/Admin"));

function App() {
  return (
    <Router>
      <div className="main-container">
        <Navbar />
        <Suspense fallback={<h3 style={{ color: "white" }}>Loading...</h3>}>
          <Routes>
            <Route path="/" element={<LazyHome />} />
            <Route path="admin" element={<LazyAdmin />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
